<!-- eslint-disable no-console -->
<script>
import Layout from "../../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import api from "../../../../common/axios";
import VueNumeric from "vue-numeric";

/**
 * Advanced table component
 */
export default {
  page: {
    title: "Tambah Logistic Company",
    meta: [{ name: "description", content: appConfig.description }],
  },
  components: { Layout, PageHeader, VueNumeric },
  data() {
    return {
      title: "Tambah Logistic Company",
      config: {
        redirect: "logistic",
        api: "logistic_company",
        upload: "media",
        province: "province",
        city: "cities?province_id=",
        industry: "industry?orderby=id&sort=desc",
      },
      model: {
        province_id: "",
        city_id: "",
        industry_id: "",
        certificates: {
          alfa_ifa: false,
          iata: false,
          fiata: false,
          dangerous_good: false,
          others: false,
          other_detail: null,
        },
      },

      options: [
        { item: { alfa_ifa: true }, name: "ALFA/IFA" },
        { item: { iata: false }, name: "IATA" },
        { item: { fiata: false }, name: "FIATA" },
        { item: { dangerous_good: false }, name: "Dangerous Goood" },
        { item: { others: false }, name: "Other" },
      ],
      image_deed: null,
      image_siup: null,
      image_sdkp: null,
      image_npwp: null,
      image_nib: null,
      items: [
        {
          text: "Logistic Company",
        },
        {
          text: "Tambah Logistic Company",
          active: true,
        },
      ],
      provinces: [],
      cities: [],
      industries: [],
      status: [
        { text: "Aktif", value: "aktif" },
        { text: "Tidak Aktif", value: "tidak aktif" },
      ],

      submitform: false,
      responses: {
        message: null,
      },
      errorPwd: false,
      errPwdMessage: null,
    };
  },

  methods: {
    renamer: function () {
      return "File Uploaded";
    },
    getData() {
      let _ = this;
      if (this.$route.meta.action == "edit") {
        api.get(_.config.api + "/" + this.$route.params.id).then((response) => {
          let _ = this;
          _.model = response.data.data;
          _.model.deed_of_incorporation_path =
            _.model.legal_documents.deed_of_incorporation_path;
          _.model.siup_path = _.model.legal_documents.siup_path;
          _.model.npwp_path = _.model.legal_documents.npwp_path;
          _.model.skdp_path = _.model.legal_documents.skdp_path;
          _.model.nib_path = _.model.legal_documents.nib_path;
          _.model.status = _.model.account_status;
        });
      }
    },
    getProvince() {
      let _ = this;
      api.get(_.config.province).then((response) => {
        let _ = this;
        _.provinces = response.data.data;
      });
    },
    getIndustry() {
      let _ = this;
      api.get(_.config.industry).then((response) => {
        let _ = this;
        _.industries = response.data.data.rows;
      });
    },
    getCity() {
      let _ = this;
      api.get(_.config.city + _.model.province_id).then((response) => {
        let _ = this;
        _.cities = response.data.data;
      });
    },
    image_deed_upload(e) {
      let _ = this;
      _.image_deed = "file_selected";
      let file = e.target.files[0];
      let formData = new FormData();
      let date = new Date();
      formData.append("file", file);
      formData.append("description", "logistic_deed_of_Incorporation");
      formData.append("title", "logistic_deed_of_Incorporation" + date);
      formData.append("status", "active");
      api
        .post(_.config.upload, formData)
        .then((res) => {
          _.model.deed_of_incorporation_path = res.data.data.file;
        })
        .catch((e) => {
          console.log(e);
        });
    },
    upload_npwp(e) {
      let _ = this;
      let file = e.target.files[0];
      let formData = new FormData();
      let date = new Date();
      formData.append("file", file);
      formData.append("description", "logistic_npwp");
      formData.append("title", "logistic_npwp" + date);
      formData.append("status", "active");
      api
        .post(_.config.upload, formData)
        .then((res) => {
          _.model.npwp_path = res.data.data.file;
        })
        .catch((e) => {
          console.log(e);
        });
    },
    upload_siup(e) {
      let _ = this;
      let file = e.target.files[0];
      let formData = new FormData();
      let date = new Date();
      formData.append("file", file);
      formData.append("description", "logistic_siup");
      formData.append("title", "logistic_siup" + date);
      formData.append("status", "active");
      api
        .post(_.config.upload, formData)
        .then((res) => {
          _.model.siup_path = res.data.data.file;
        })
        .catch((e) => {
          console.log(e);
        });
    },
    upload_sdkp(e) {
      let _ = this;
      let file = e.target.files[0];
      let formData = new FormData();
      let date = new Date();
      formData.append("file", file);
      formData.append("description", "logistic_sdkp");
      formData.append("title", "logistic_sdkp" + date);
      formData.append("status", "active");
      api
        .post(_.config.upload, formData)
        .then((res) => {
          _.model.skdp_path = res.data.data.file;
        })
        .catch((e) => {
          console.log(e);
        });
    },
    upload_nib(e) {
      let _ = this;
      let file = e.target.files[0];
      let formData = new FormData();
      let date = new Date();
      formData.append("file", file);
      formData.append("description", "logistic_nib");
      formData.append("title", "logistic_nib" + date);
      formData.append("status", "active");
      api
        .post(_.config.upload, formData)
        .then((res) => {
          _.model.nib_path = res.data.data.file;
        })
        .catch((e) => {
          console.log(e);
        });
    },

    validatePassword() {
      var lowerCaseLetters = /[a-z]/g;
      var upperCaseLetters = /[A-Z]/g;
      var numbers = /[0-9]/g;
      // eslint-disable-next-line no-useless-escape
      var symbol = /[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;

      if (!this.model.password.match(numbers)) {
        this.errorPwd = true;
        this.errPwdMessage =
          "Password harus mengandung kombinasi huruf besar, huruf kecil angka, dan karakter khusus.";
      } else if (!this.model.password.match(upperCaseLetters)) {
        this.errorPwd = true;
        this.errPwdMessage =
          "Password harus mengandung kombinasi huruf besar, huruf kecil angka, dan karakter khusus.";
      } else if (!this.model.password.match(lowerCaseLetters)) {
        this.errorPwd = true;
        this.errPwdMessage =
          "Password harus mengandung kombinasi huruf besar, huruf kecil angka, dan karakter khusus.";
      } else if (!this.model.password.match(symbol)) {
        this.errorPwd = true;
        this.errPwdMessage =
          "Password harus mengandung kombinasi huruf besar, huruf kecil angka, dan karakter khusus.";
      } else {
        this.errorPwd = false;
        this.errPwdMessage = "";
      }
    },
    save() {
      let _ = this;
      if (this.$route.meta.action == "store") {
        let data_post = {
          industry_id: _.model.industry_id,
          province_id: _.model.province_id,
          city_id: _.model.city_id,
          name: _.model.name,
          email: _.model.email,
          phone: _.model.phone,
          address: _.model.address,
          zip_code: _.model.zip_code,
          contact_person: _.model.contact_person,
          yearly_income: _.model.yearly_income.toString(),
          password: _.model.password,
          account_status: _.model.status,
          document_status: "disetujui",
          certificates: {
            alfa_ifa: _.model.certificates.alfa_ifa,
            iata: _.model.certificates.iata,
            fiata: _.model.certificates.fiata,
            dangerous_good: _.model.certificates.dangerous_good,
            others: _.model.certificates.others,
            other_detail:
              _.model.certificates.others == true
                ? _.model.certificates.other_detail
                : null,
          },
          legal_documents: {
            deed_of_incorporation_path: _.model.deed_of_incorporation_path,
            siup_path: _.model.siup_path,
            npwp_path: _.model.npwp_path,
            skdp_path: _.model.skdp_path,
            nib_path: _.model.nib_path,
          },
        };
        api
          .post(_.config.api, data_post)
          .then(() => {
            _.$router.push({
              name: _.config.redirect,
              params: {
                event: "success",
                icon: "fas fa-check-circle",
                title: "Create Success",
              },
            });
          })
          .catch((e) => {
            // console.log(e.response.data.message);
            this.responses.message = e.response.data.message;
          });
      } else {
        let data_post = {
          industry_id: _.model.industry_id,
          province_id: _.model.province_id,
          city_id: _.model.city_id,
          name: _.model.name,
          email: _.model.email,
          phone: _.model.phone,
          address: _.model.address,
          yearly_income: _.model.yearly_income.toString(),
          zip_code: _.model.zip_code,
          contact_person: _.model.contact_person,
          account_status: _.model.status,
          certificates: {
            alfa_ifa: _.model.certificates.alfa_ifa,
            iata: _.model.certificates.iata,
            fiata: _.model.certificates.fiata,
            dangerous_good: _.model.certificates.dangerous_good,
            others: _.model.certificates.others,
            other_detail:
              _.model.certificates.others == true
                ? _.model.certificates.other_detail
                : null,
          },
          legal_documents: {
            deed_of_incorporation_path: _.model.deed_of_incorporation_path,
            siup_path: _.model.siup_path,
            npwp_path: _.model.npwp_path,
            skdp_path: _.model.skdp_path,
            nib_path: _.model.nib_path,
          },
        };

        api
          .put(_.config.api + "/" + _.$route.params.id, data_post)
          .then(() => {
            _.$router.push({
              name: _.config.redirect,
              params: {
                event: "success",
                icon: "fas fa-check-circle",
                title: "Edit Success",
              },
            });
          })
          .catch((e) => {
            this.responses.message = e.response.data.message;
          });
      }
    },
  },
  mounted() {
    this.getProvince();
    this.getIndustry();
    this.getData();
  },
  watch: {
    "model.province_id"() {
      this.getCity();
    },
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />

    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <b-alert
              v-if="responses.message"
              variant="danger"
              class="mb-0"
              show
              dismissible
            >
              <p class="mb-0">{{ responses.message }}</p>
            </b-alert>
            <ValidationObserver v-slot="{ handleSubmit }">
              <b-form
                @submit.prevent="handleSubmit(save)"
                class="form-horizontal"
              >
                <div class="row">
                  <div class="col-md-9 mt-4">
                    <div class="row">
                      <div
                        :class="
                          $route.meta.action == 'store'
                            ? 'col-md-6'
                            : 'col-md-12'
                        "
                      >
                        <ValidationProvider
                          name="Name"
                          rules="required"
                          v-slot="{ errors }"
                        >
                          <b-form-group
                            id="input-group-2"
                            label="Nama"
                            label-for="input-2"
                          >
                            <b-form-input
                              id="input-2"
                              v-model="model.name"
                              type="text"
                            ></b-form-input>
                            <p class="fs-6 text-danger">
                              {{ errors[0] }}
                            </p>
                          </b-form-group>
                        </ValidationProvider>
                      </div>

                      <div class="col-md-6">
                        <ValidationProvider
                          name="Email"
                          rules="required"
                          v-slot="{ errors }"
                        >
                          <b-form-group
                            id="input-group-2"
                            label="Email"
                            label-for="input-2"
                          >
                            <b-form-input
                              id="input-2"
                              v-model="model.email"
                              type="email"
                            ></b-form-input>
                            <p class="fs-6 text-danger">
                              {{ errors[0] }}
                            </p>
                          </b-form-group>
                        </ValidationProvider>
                      </div>

                      <!-- required|regex:^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*_=+-]).|min:8 -->
                      <div
                        class="col-md-6"
                        v-if="$route.meta.action == 'store'"
                      >
                        <ValidationProvider
                          name="Password"
                          :rules="
                            $route.meta.action == 'store'
                              ? 'required|min:8'
                              : 'min:8'
                          "
                          v-slot="{ errors }"
                        >
                          <b-form-group
                            id="input-group-2"
                            label="Password"
                            label-for="input-2"
                          >
                            <b-form-input
                              id="input-2"
                              v-model="model.password"
                              type="password"
                              @change="validatePassword"
                            ></b-form-input>
                            <p v-show="!errorPwd" class="fs-6 text-danger">
                              {{ errors[0] }}
                            </p>

                            <p v-show="errorPwd" class="fs-6 text-danger">
                              {{ errPwdMessage }}
                            </p>
                          </b-form-group>
                        </ValidationProvider>
                      </div>

                      <div class="col-md-6">
                        <ValidationProvider
                          name="Phone"
                          rules="required"
                          v-slot="{ errors }"
                        >
                          <b-form-group
                            id="input-group-2"
                            label="Phone Number"
                            label-for="input-2"
                          >
                            <b-form-input
                              id="input-2"
                              v-model="model.phone"
                              type="text"
                            ></b-form-input>
                            <p class="fs-6 text-danger">
                              {{ errors[0] }}
                            </p>
                          </b-form-group>
                        </ValidationProvider>
                      </div>

                      <div class="col-md-12">
                        <ValidationProvider
                          name="Address"
                          rules="required"
                          v-slot="{ errors }"
                        >
                          <b-form-group
                            id="input-group-2"
                            label="Address"
                            label-for="input"
                          >
                            <textarea
                              class="form-control"
                              id="exampleFormControlTextarea1"
                              rows="3"
                              v-model="model.address"
                            ></textarea>
                            <p class="fs-6 text-danger">
                              {{ errors[0] }}
                            </p>
                          </b-form-group>
                        </ValidationProvider>
                      </div>

                      <div class="col-md-4">
                        <ValidationProvider
                          name="Province"
                          rules="required"
                          v-slot="{ errors }"
                        >
                          <b-form-group
                            id="input-group-2"
                            label="Province"
                            label-for="input"
                          >
                            <select
                              class="form-control"
                              v-model="model.province_id"
                            >
                              <option value="">Pilih Provinsi</option>
                              <option
                                v-for="(i, index) in provinces"
                                :key="index"
                                :value="i.id"
                              >
                                {{ i.name }}
                              </option>
                            </select>

                            <p class="fs-6 text-danger">
                              {{ errors[0] }}
                            </p>
                          </b-form-group>
                        </ValidationProvider>
                      </div>

                      <div class="col-md-4">
                        <ValidationProvider
                          name="City"
                          rules="required"
                          v-slot="{ errors }"
                        >
                          <b-form-group
                            id="input-group-2"
                            label="City"
                            label-for="input"
                          >
                            <select
                              class="form-control"
                              v-model="model.city_id"
                            >
                              <option value="">Pilih Kota</option>
                              <option
                                v-for="(i, index) in cities"
                                :key="index"
                                :value="i.id"
                              >
                                {{ i.name }}
                              </option>
                            </select>
                            <p class="fs-6 text-danger">
                              {{ errors[0] }}
                            </p>
                          </b-form-group>
                        </ValidationProvider>
                      </div>

                      <div class="col-md-4">
                        <ValidationProvider
                          name="ZIP Code"
                          rules="required"
                          v-slot="{ errors }"
                        >
                          <b-form-group
                            id="input-group-2"
                            label="ZIP Code"
                            label-for="input-2"
                          >
                            <b-form-input
                              id="input-2"
                              v-model="model.zip_code"
                              type="text"
                            ></b-form-input>
                            <p class="fs-6 text-danger">
                              {{ errors[0] }}
                            </p>
                          </b-form-group>
                        </ValidationProvider>
                      </div>

                      <div class="col-md-4">
                        <ValidationProvider
                          name="Industry"
                          rules="required"
                          v-slot="{ errors }"
                        >
                          <b-form-group
                            id="input-group-2"
                            label="Industry"
                            label-for="input"
                          >
                            <select
                              class="form-control"
                              v-model="model.industry_id"
                            >
                              <option value="">Pilih Industri</option>
                              <option
                                v-for="(i, index) in industries"
                                :key="index"
                                :value="i.id"
                              >
                                {{ i.name }}
                              </option>
                            </select>
                            <p class="fs-6 text-danger">
                              {{ errors[0] }}
                            </p>
                          </b-form-group>
                        </ValidationProvider>
                      </div>

                      <div class="col-md-4">
                        <ValidationProvider
                          name="Contact person"
                          rules="required"
                          v-slot="{ errors }"
                        >
                          <b-form-group
                            id="input-group-2"
                            label="Contact Person"
                            label-for="input-2"
                          >
                            <b-form-input
                              id="input-2"
                              v-model="model.contact_person"
                              type="text"
                            ></b-form-input>
                            <p class="fs-6 text-danger">
                              {{ errors[0] }}
                            </p>
                          </b-form-group>
                        </ValidationProvider>
                      </div>

                      <div class="col-md-4">
                        <ValidationProvider
                          name="Yearly Income"
                          rules="required"
                          v-slot="{ errors }"
                        >
                          <b-form-group
                            id="input-group-2"
                            label="Yearly Income"
                            label-for="input-2"
                          >
                            <vue-numeric
                              class="form-control"
                              separator=","
                              v-bind:precision="2"
                              name="income"
                              v-model="model.yearly_income"
                            ></vue-numeric>
                            <p class="fs-6 text-danger">
                              {{ errors[0] }}
                            </p>
                          </b-form-group>
                        </ValidationProvider>
                      </div>

                      <div class="col-md-7">
                        <b-form-group
                          id="input-group-2"
                          label="Certificates"
                          label-for="input-2"
                        >
                          <div class="form-check form-check-inline">
                            <b-form-checkbox
                              v-model="model.certificates.alfa_ifa"
                            >
                              ALFA/IFA
                            </b-form-checkbox>
                          </div>

                          <div class="form-check form-check-inline">
                            <b-form-checkbox v-model="model.certificates.iata">
                              IATA
                            </b-form-checkbox>
                          </div>

                          <div class="form-check form-check-inline">
                            <b-form-checkbox v-model="model.certificates.fiata">
                              FIATA
                            </b-form-checkbox>
                          </div>
                          <div class="form-check form-check-inline">
                            <b-form-checkbox
                              v-model="model.certificates.dangerous_good"
                            >
                              Dangerous Good
                            </b-form-checkbox>
                          </div>
                          <div class="form-check form-check-inline">
                            <b-form-checkbox
                              v-model="model.certificates.others"
                            >
                              Other
                            </b-form-checkbox>
                          </div>
                        </b-form-group>
                      </div>

                      <div class="col-md-5">
                        <ValidationProvider
                          name="Status"
                          rules="required"
                          v-slot="{ errors }"
                        >
                          <b-form-group
                            id="input-group-2"
                            label="Status"
                            label-for="input-2"
                          >
                            <b-form-radio-group
                              id="radio-group-1"
                              v-model="model.status"
                              :options="status"
                              name="radio-options"
                            ></b-form-radio-group>
                            <p class="fs-6 text-danger">
                              {{ errors[0] }}
                            </p>
                          </b-form-group>
                        </ValidationProvider>
                      </div>

                      <div
                        class="col-md-12"
                        v-if="model.certificates.others == true"
                      >
                        <ValidationProvider
                          name="Other Detail"
                          rules="required"
                          v-slot="{ errors }"
                        >
                          <b-form-group
                            id="input-group-2"
                            label="Other Detail"
                            label-for="input-2"
                          >
                            <b-form-input
                              id="input-2"
                              v-model="model.certificates.other_detail"
                              type="text"
                            ></b-form-input>
                            <p class="fs-6 text-danger">
                              {{ errors[0] }}
                            </p>
                          </b-form-group>
                        </ValidationProvider>
                      </div>

                      <div class="col-md-6">
                        <div class="form-group row">
                          <div class="col-sm-5">
                            <b-button
                              type="button"
                              to="/logistic"
                              variant="light"
                              class="w-100"
                              >Kembali</b-button
                            >
                          </div>

                          <div class="col-sm-5">
                            <b-button
                              type="submit"
                              variant="primary"
                              class="w-100"
                              >Simpan</b-button
                            >
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="col-md-3 mt-4 border border-primary rounded">
                    <div class="row">
                      <div class="col-md-12">
                        <h6 class="text-center m-2">
                          Upload Company Legal Document
                        </h6>
                      </div>

                      <div class="col-md-12 mt-2">
                        <ValidationProvider
                          name="Deed of Incorporation"
                          :rules="
                            $route.meta.action == 'store'
                              ? 'required|ext:jpeg,png,jpg,pdf'
                              : 'ext:jpeg,png,jpg,pdf'
                          "
                          v-slot="{ errors }"
                        >
                          <b-form-group
                            id="input-group-2"
                            label="Deed of Incorporation"
                            label-for="input-2"
                          >
                            <b-form-file
                              v-model="image_deed"
                              :file-name-formatter="renamer"
                              @change="image_deed_upload"
                              placeholder="Choose a file..."
                              accept=".jpeg,.jpg,.gif,.png,.pdf"
                            />
                            <p class="fs-6 text-danger">
                              {{ errors[0] }}
                            </p>
                          </b-form-group>
                        </ValidationProvider>
                      </div>

                      <div class="col-md-12">
                        <ValidationProvider
                          name="SIUP"
                          :rules="
                            $route.meta.action == 'store'
                              ? 'required|ext:jpeg,png,jpg'
                              : 'ext:jpeg,png,jpg'
                          "
                          v-slot="{ errors }"
                        >
                          <b-form-group
                            id="input-group-2"
                            label="SIUP"
                            label-for="input-2"
                          >
                            <b-form-file
                              v-model="image_siup"
                              :file-name-formatter="renamer"
                              @change="upload_siup"
                              placeholder="Choose a file..."
                              accept=".jpeg,.jpg,.gif,.png,.pdf"
                            />
                            <p class="fs-6 text-danger">
                              {{ errors[0] }}
                            </p>
                          </b-form-group>
                        </ValidationProvider>
                      </div>

                      <div class="col-md-12">
                        <ValidationProvider
                          name="NPWP"
                          :rules="
                            $route.meta.action == 'store'
                              ? 'required|ext:jpeg,png,jpg'
                              : 'ext:jpeg,png,jpg'
                          "
                          v-slot="{ errors }"
                        >
                          <b-form-group
                            id="input-group-2"
                            label="NPWP"
                            label-for="input-2"
                          >
                            <b-form-file
                              v-model="image_npwp"
                              :file-name-formatter="renamer"
                              @change="upload_npwp"
                              placeholder="Choose a file..."
                              accept=".jpeg,.jpg,.gif,.png,.pdf"
                            />
                            <p class="fs-6 text-danger">
                              {{ errors[0] }}
                            </p>
                          </b-form-group>
                        </ValidationProvider>
                      </div>

                      <div class="col-md-12">
                        <ValidationProvider
                          name="SKDP"
                          :rules="
                            $route.meta.action == 'store'
                              ? 'required|ext:jpeg,png,jpg'
                              : 'ext:jpeg,png,jpg'
                          "
                          v-slot="{ errors }"
                        >
                          <b-form-group
                            id="input-group-2"
                            label="SKDP"
                            label-for="input-2"
                          >
                            <b-form-file
                              v-model="image_sdkp"
                              :file-name-formatter="renamer"
                              @change="upload_sdkp"
                              placeholder="Choose a file..."
                              accept=".jpeg,.jpg,.gif,.png,.pdf"
                            />
                            <p class="fs-6 text-danger">
                              {{ errors[0] }}
                            </p>
                          </b-form-group>
                        </ValidationProvider>
                      </div>

                      <div class="col-md-12">
                        <ValidationProvider
                          name="NIB"
                          :rules="
                            $route.meta.action == 'store'
                              ? 'required|ext:jpeg,png,jpg'
                              : 'ext:jpeg,png,jpg'
                          "
                          v-slot="{ errors }"
                        >
                          <b-form-group
                            id="input-group-2"
                            label="NIB"
                            label-for="input-2"
                          >
                            <b-form-file
                              v-model="image_nib"
                              :file-name-formatter="renamer"
                              @change="upload_nib"
                              placeholder="Choose a file..."
                              accept=".jpeg,.jpg,.gif,.png,.pdf"
                            />
                            <p class="fs-6 text-danger">
                              {{ errors[0] }}
                            </p>
                          </b-form-group>
                        </ValidationProvider>
                      </div>
                    </div>
                  </div>
                </div>
              </b-form>
            </ValidationObserver>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>

<style lanng="scss" scoped>
.form-control {
  border: 1.5px solid #ced4da !important;
}
</style>
